@import "../../global.scss";
.lesson-video-page-wrapper {
  .chat-container {
    background-color: #263238;
    .card {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .chat-input {
    width: 100%;
    background-color: $white;
    border-top: 1px solid $lt-grey;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
