// Font Imports
@import url("https://fonts.googleapis.com/css?family=Montserrat|Open+Sans");

$lt-grey: #ddd;
$grey: #555;
$primary-navy: #085078;
$primary-teal: #85d8ce;
$white: #fff;
$red: #fb6c74;
$green: #43a506;

// Font Families
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}
p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif;
}

// Links
button {
  &.btn-link {
    color: $primary-navy;
    &:hover {
      color: $primary-teal;
    }
  }
}
p {
  a {
    color: $primary-navy;
    &:hover {
      color: $primary-teal;
    }
  }
}

// Background Colors
.navy-to-teal-bg {
  background: $primary-teal;
  background: -webkit-linear-gradient(to left, $primary-teal, $primary-navy);
  background: linear-gradient(to left, $primary-teal, $primary-navy);
  z-index: 999;
  .invert-text-color {
    color: $white;
  }
}

// Colors
.navy {
  color: $primary-navy;
}
.white {
  color: $white;
}
.lt-grey {
  color: $lt-grey;
}
.green {
  color: $green;
}

// Cursor
.pointer {
  cursor: pointer;
}
