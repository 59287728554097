@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans);
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.btn {
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 5px;
  min-width: 145px;
  /* White to Dark Grey Transition Button */
  /* Light Grey to Navy Blue Transition Button */
  /* Match Making Button */
  /* Upload Image Button */ }
  .btn.white-cta {
    border: 1px solid #085078;
    background-color: #fff;
    color: #085078 !important;
    -webkit-transition: background-color 0.8s ease, color 0.1s ease;
    transition: background-color 0.8s ease, color 0.1s ease; }
    .btn.white-cta:hover {
      background-color: #555;
      color: #fff !important;
      text-decoration: none; }
  .btn.lt-grey-cta {
    border: 1px solid #085078;
    background-color: #ddd;
    color: #085078 !important;
    -webkit-transition: background-color 0.8s ease, color 0.1s ease;
    transition: background-color 0.8s ease, color 0.1s ease; }
    .btn.lt-grey-cta:hover {
      background-color: #085078;
      color: #fff !important;
      text-decoration: none; }
  .btn.match-making-cta {
    background-color: #85d8ce;
    color: #fff;
    border-radius: 25px;
    -webkit-transition: background-color 0.8s ease, color 0.1s ease;
    transition: background-color 0.8s ease, color 0.1s ease;
    min-width: 260px; }
    .btn.match-making-cta:hover {
      background-color: #085078;
      color: #fff !important;
      text-decoration: none; }
  .btn.upload-image-btn {
    border: 3px solid #085078;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    min-width: 0;
    min-width: initial;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    -webkit-transition: border-color 0.5s ease;
    transition: border-color 0.5s ease; }
    .btn.upload-image-btn:hover {
      border-color: #85d8ce; }

.loading-text {
  color: #085078; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.navbar-nav {
  align-items: center; }

.white-links .nav-item .nav-link {
  color: #fff !important;
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease; }
  .white-links .nav-item .nav-link:hover {
    color: rgba(255, 255, 255, 0.7) !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.profile-picture-wrapper {
  border: 2px solid #085078;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: cover;
  display: inline-block; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.user-list-wrapper {
  max-height: 300px;
  overflow-y: auto; }
  .user-list-wrapper li {
    padding: 10px 20px;
    border-top: 1px solid rgba(221, 221, 221, 0.4);
    border-bottom: 1px solid rgba(221, 221, 221, 0.4); }
    .user-list-wrapper li:first-child {
      border-top: none; }
    .user-list-wrapper li:last-child {
      border-bottom: none; }
  .user-list-wrapper .username {
    margin-bottom: 19px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: bottom; }

.navbar-brand {
  padding-top: 10px !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.signup-form-component-wrapper {
  background-color: #fff;
  padding: 35px 25px 10px 25px;
  border-radius: 5px; }
  .signup-form-component-wrapper label {
    color: #555; }
  .signup-form-component-wrapper .form-divider {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    z-index: 1;
    color: #555; }
    .signup-form-component-wrapper .form-divider:before {
      content: "";
      border-top: 1px solid #ddd;
      margin: 0 auto;
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1; }
    .signup-form-component-wrapper .form-divider span {
      background-color: #fff;
      padding: 0 10px; }
  .signup-form-component-wrapper a {
    color: #085078; }
    .signup-form-component-wrapper a:hover {
      color: #85d8ce; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.home-page-wrapper {
  min-height: 100vh;
  color: #fff;
  padding-top: 70px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.image-uploader-wrapper .custom-file-label::after {
  color: #085078; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.user-info-tab-wrapper .profile-picture {
  border: 2px solid #085078;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  background-size: cover; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.settings-page-wrapper {
  color: #085078;
  padding-top: 70px; }
  .settings-page-wrapper .tab-content {
    background-color: #fff;
    height: 500px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-left-radius: initial;
    border-top-right-radius: 0;
    border-top-right-radius: initial;
    padding: 20px; }
  .settings-page-wrapper .settings-tabs {
    background-color: #ddd;
    border-radius: 5px;
    padding: 20px 10px;
    min-height: 450px; }
  .settings-page-wrapper .nav-item {
    cursor: pointer; }
    .settings-page-wrapper .nav-item .nav-link {
      border: medium none currentColor;
      border: initial; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.iframe-wrapper iframe {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999; }

.code-editor-title {
  background-color: #253238;
  border-top: 1px solid #546e7a; }

.side-menu-wrapper {
  width: 500px;
  border-bottom-left-radius: 5px;
  background-color: white;
  position: absolute;
  right: 0;
  top: 76px;
  z-index: 10;
  -webkit-transition: right 0.3s ease;
  transition: right 0.3s ease; }
  .side-menu-wrapper.closed {
    position: fixed;
    right: -500px; }
  @media (max-width: 576px) {
    .side-menu-wrapper {
      width: auto;
      width: initial; } }

.chat-container-wrapper .bubble1,
.chat-container-wrapper .bubble2 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.chat-container-wrapper .bubble1 div {
  background: #42a5f5;
  color: #fff !important;
  border-radius: 20px 20px 3px 20px;
  display: block;
  max-width: 75%;
  padding: 7px 13px 7px 13px; }

.chat-container-wrapper .bubble1:before {
  content: attr(data-is);
  position: absolute;
  right: 15px;
  bottom: -0.8em;
  display: block;
  font-size: 0.75rem;
  color: #546e7a; }

.chat-container-wrapper .bubble2 div {
  background: #f1f1f1;
  color: #000 !important;
  border-radius: 20px 20px 20px 3px;
  display: block;
  max-width: 75%;
  padding: 7px 13px 7px 13px; }

.chat-container-wrapper .bubble2:before {
  content: attr(data-is);
  position: absolute;
  left: 13px;
  bottom: -0.8em;
  display: block;
  font-size: 0.75rem;
  color: #546e7a; }

.chat-container-wrapper div {
  text-decoration: none !important; }

.chat-container-wrapper label {
  color: #78909c !important; }

.chat-container-wrapper .card::-webkit-scrollbar {
  width: 1px; }

.chat-container-wrapper ::-webkit-scrollbar-thumb {
  border-radius: 9px;
  background: rgba(96, 125, 139, 0.99); }

#messages-wrapper {
  height: 275px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.chat-input {
  width: 500px;
  height: 40px;
  background-color: rgba(221, 221, 221, 0.4);
  border: none;
  padding: 0 10px;
  color: #555; }
  .chat-input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(85, 85, 85, 0.4);
    opacity: 1;
    /* Firefox */ }
  .chat-input::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(85, 85, 85, 0.4);
    opacity: 1;
    /* Firefox */ }
  .chat-input:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(85, 85, 85, 0.4);
    opacity: 1;
    /* Firefox */ }
  .chat-input::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(85, 85, 85, 0.4);
    opacity: 1;
    /* Firefox */ }
  .chat-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(85, 85, 85, 0.4);
    opacity: 1;
    /* Firefox */ }
  .chat-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(85, 85, 85, 0.4); }
  .chat-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(85, 85, 85, 0.4); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.room-chat-component-wrapper .chat-container-wrapper {
  border: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  min-height: 55vh; }

.room-chat-component-wrapper .chat-input {
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 20px; }

.room-chat-component-wrapper #messages-wrapper {
  min-height: 450px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.hamburger-button {
  position: absolute;
  height: 50px;
  width: 50px;
  background: #ddd;
  color: #085078;
  border: 1px solid #555;
  border-top: none;
  border-bottom-left-radius: 5px; }
  .hamburger-button.right-panel-burger-button {
    top: 76px;
    right: 0; }
  .hamburger-button.lessons-panel-burger-button {
    border-top: 1px solid #555;
    border-top-left-radius: 5px;
    top: 150px;
    right: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.course-card-wrapper {
  cursor: pointer;
  border: 1px solid #085078;
  background-color: #ddd;
  min-height: 200px;
  color: #085078;
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 15px 10px;
  -webkit-transition: background-color 0.5s ease, color 0.5s ease;
  transition: background-color 0.5s ease, color 0.5s ease; }
  .course-card-wrapper:hover {
    background-color: #085078; }
    .course-card-wrapper:hover h4,
    .course-card-wrapper:hover h6,
    .course-card-wrapper:hover p {
      color: #fff;
      -webkit-transition: color 0.5s ease;
      transition: color 0.5s ease; }
  @media (max-width: 576px) {
    .course-card-wrapper {
      min-height: 300px; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.courses-page-wrapper {
  padding-top: 50px; }
  .courses-page-wrapper h2,
  .courses-page-wrapper p {
    color: #085078; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.login-form-component-wrapper {
  background-color: #fff;
  padding: 35px 25px 10px 25px;
  border-radius: 5px; }
  .login-form-component-wrapper label {
    color: #555; }
  .login-form-component-wrapper .form-divider {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    z-index: 1;
    color: #555; }
    .login-form-component-wrapper .form-divider:before {
      content: "";
      border-top: 1px solid #ddd;
      margin: 0 auto;
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1; }
    .login-form-component-wrapper .form-divider span {
      background-color: #fff;
      padding: 0 10px; }
  .login-form-component-wrapper .btn-link {
    color: #085078; }
    .login-form-component-wrapper .btn-link:hover {
      color: #85d8ce; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.login-page-wrapper {
  padding-top: 80px;
  background-color: rgba(221, 221, 221, 0.4);
  height: 100vh; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.signup-page-wrapper {
  padding-top: 80px;
  background-color: rgba(221, 221, 221, 0.4);
  height: 100vh; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.breadcrumb {
  background-color: transparent !important;
  background-color: initial !important;
  margin-bottom: 0 !important; }
  .breadcrumb a {
    color: #085078; }

.lesson-pannel-wrapper .panel {
  cursor: pointer;
  background-color: #ddd;
  border: 1px solid #085078;
  border-radius: 5px;
  padding: 15px 20px;
  min-height: 214px;
  color: #085078;
  -webkit-transition: background-color 0.5s ease, color 0.5s ease;
  transition: background-color 0.5s ease, color 0.5s ease; }
  .lesson-pannel-wrapper .panel:hover {
    background-color: #085078; }
    .lesson-pannel-wrapper .panel:hover h2,
    .lesson-pannel-wrapper .panel:hover h4,
    .lesson-pannel-wrapper .panel:hover p {
      color: #fff;
      -webkit-transition: color 0.5s ease;
      transition: color 0.5s ease; }

.lesson-pannel-wrapper .lesson-completion-icon {
  margin-top: 80px; }

.lesson-pannel-wrapper .red {
  color: #fb6c74; }

.level-box-wrapper {
  background-color: #085078;
  border-radius: 5px;
  color: #fff;
  padding: 28px 0; }
  .level-box-wrapper .user-course-score {
    width: 70px;
    height: 70px;
    background-color: #fff;
    color: #085078;
    font-size: 48px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.login-modal-wrapper h5,
.login-modal-wrapper p {
  color: #085078; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.login-modal-wrapper h5,
.login-modal-wrapper p {
  color: #085078; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.language-lesson-page-wrapper {
  padding-top: 50px; }
  .language-lesson-page-wrapper h1,
  .language-lesson-page-wrapper p {
    color: #085078; }
    .language-lesson-page-wrapper h1 small,
    .language-lesson-page-wrapper p small {
      color: #555; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.bug-form-component-wrapper {
  background-color: #fff;
  padding: 35px 25px 10px 25px;
  border-radius: 5px; }
  .bug-form-component-wrapper label {
    color: #555; }
  .bug-form-component-wrapper .form-divider {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    z-index: 1;
    color: #555; }
    .bug-form-component-wrapper .form-divider:before {
      content: "";
      border-top: 1px solid #ddd;
      margin: 0 auto;
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1; }
    .bug-form-component-wrapper .form-divider span {
      background-color: #fff;
      padding: 0 10px; }
  .bug-form-component-wrapper a {
    color: #085078; }
    .bug-form-component-wrapper a:hover {
      color: #85d8ce; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.bug-page-wrapper {
  padding-top: 80px;
  background-color: rgba(221, 221, 221, 0.4);
  height: 100vh; }

.tabs-wrapper .badge {
  position: relative;
  bottom: 8px;
  right: 2px;
  border-radius: 10px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.friend-item-wrapper {
  padding-top: 5px; }
  .friend-item-wrapper .friend-list-item {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    .friend-item-wrapper .friend-list-item:first-child {
      border-top: none; }
    .friend-item-wrapper .friend-list-item:last-child {
      border-bottom: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.profile-picture-wrapper {
  border: 2px solid #085078;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: cover;
  display: inline-block;
  margin-top: 4px; }

.request-item-wrapper {
  padding-top: 5px; }
  .request-item-wrapper .request-list-item {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    .request-item-wrapper .request-list-item:first-child {
      border-top: none; }
    .request-item-wrapper .request-list-item:last-child {
      border-bottom: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.chat-box-wrapper {
  z-index: 999;
  width: 500px;
  height: 400px;
  background: #fff;
  border: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: fixed;
  right: 25px;
  -webkit-transition: bottom 0.2s ease;
  transition: bottom 0.2s ease; }
  .chat-box-wrapper .nav-item {
    cursor: pointer; }
    .chat-box-wrapper .nav-item svg {
      color: #085078; }
  .chat-box-wrapper .chat-toggle {
    margin-left: 300px; }
  @media (max-width: 576px) {
    .chat-box-wrapper {
      width: 100%;
      right: 0; }
      .chat-box-wrapper .chat-toggle {
        margin-left: 150px; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

p,
ul,
ol,
label {
  font-family: "Open Sans", sans-serif; }

button.btn-link {
  color: #085078; }
  button.btn-link:hover {
    color: #85d8ce; }

p a {
  color: #085078; }
  p a:hover {
    color: #85d8ce; }

.navy-to-teal-bg {
  background: #85d8ce;
  background: -webkit-gradient(linear, right top, left top, from(#85d8ce), to(#085078));
  background: linear-gradient(to left, #85d8ce, #085078);
  z-index: 999; }
  .navy-to-teal-bg .invert-text-color {
    color: #fff; }

.navy {
  color: #085078; }

.white {
  color: #fff; }

.lt-grey {
  color: #ddd; }

.green {
  color: #43a506; }

.pointer {
  cursor: pointer; }

.lesson-video-page-wrapper .chat-container {
  background-color: #263238; }
  .lesson-video-page-wrapper .chat-container .card {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }

.lesson-video-page-wrapper .chat-input {
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

